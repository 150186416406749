import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Header from '../components/header'
import Columns from '../components/columns'
import Sidebar from '../components/sidebar'
import Video from '../components/video'
import FinancialPlanningNav from '../components/financial-planning-nav'

import videos from '../lib/videos'

interface IVideosState {
  activeVideoSection: string
}

export default class Videos extends React.Component<{}, IVideosState> {
  constructor(props) {
    super(props)

    this.state = {
      activeVideoSection: null,
    }
  }

  get renderVideos() {
    return videos.browse
      .filter(video => {
        if (!this.state.activeVideoSection) return true
        return video.tags.includes(this.state.activeVideoSection)
      })
      .map(sortedVideo => {
        return (
          <div
            className="marketing__grid--half marketing__grid--mobile-wide"
            key={sortedVideo.url}
          >
            <Video youTubeUrl={sortedVideo.url} title={sortedVideo.title} />
          </div>
        )
      })
  }

  render() {
    return (
      <Layout>
        <Helmet
          title={'Financial Planning Videos'}
          meta={[
            {
              name: 'description',
              content:
                // tslint:disable-next-line:max-line-length
                'At Adage Financial Consultants, we believe that the more educated a client is about financial and retirement planning, the better decisions they will make about their financial future. In that spirit, we provide the following videos that can help you understand some of the concepts and strategies that we leverage every day to deliver results for our clients.',
            },
            {
              name: 'keywords',
              content:
                // tslint:disable-next-line:max-line-length
                'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
            },
          ]}
        />
        <Header headline={'Videos'} />
        <Columns background>
          <Sidebar>
            <nav className="video-nav">
              <h1>Sort Videos By Topic</h1>
              <ul>
                <li onClick={() => this.setState({ activeVideoSection: null })}>
                  Show All
                </li>
                <li
                  onClick={() =>
                    this.setState({ activeVideoSection: 'Retirement' })
                  }
                >
                  Retirement Planning
                </li>
                <li
                  onClick={() => this.setState({ activeVideoSection: '401k' })}
                >
                  401k
                </li>
                <li
                  onClick={() =>
                    this.setState({ activeVideoSection: 'Safe Money' })
                  }
                >
                  Safe Money
                </li>
                <li
                  onClick={() =>
                    this.setState({ activeVideoSection: 'Social Security' })
                  }
                >
                  Social Security
                </li>
                <li
                  onClick={() =>
                    this.setState({ activeVideoSection: 'Financial Planning' })
                  }
                >
                  Financial Planning
                </li>
              </ul>
              <p>
                At Adage Financial Consultants, we believe that the more
                educated a client is about financial and retirement planning,
                the better decisions they will make about their financial
                future. In that spirit, we provide the following videos that can
                help you understand some of the concepts and strategies that we
                leverage every day to deliver results for our clients.
              </p>
            </nav>
          </Sidebar>
          <div className="marketing__column">
            <div className="marketing__grid">{this.renderVideos}</div>
          </div>
        </Columns>
      </Layout>
    )
  }
}
